// styles.scss
@import "~bootstrap/scss/bootstrap";

// Custom variables
$primary: #2c3e50;
$secondary: #3498db;
$light: #f8f9fa;

// Custom styles
.profile-header {
    text-align: center;
    padding: 2rem 0;
    background: $light;

    .avatar {
        border-radius: 50%;
        margin-bottom: 1.5rem;
        border: 4px solid white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
}

.skill-pill {
    transition: all 0.3s ease;
    margin: 0.25rem;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
}

.experience-card {
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }
}

.modal-achievement {
    &::before {
        content: "🏆";
        margin-right: 0.5rem;
    }
}

.modal-technologies {
    &::before {
        content: "⚙️";
        margin-right: 0.5rem;
    }
}

.about-card {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 2rem 0;
}

.certification-section {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 2rem 0;

    .carousel {
        .carousel-inner {
            .carousel-item {
                display: none;
                justify-content: center;
                align-items: center;
                width: 100%; // Ensure full-width carousel items
                padding: 1rem 2rem;
                position: relative;
                transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;

                &.active {
                    display: block; // Show only the active slide
                }

                .d-flex {
                    width: 100%; // Ensure the content inside also stretches
                    justify-content: space-between; // Space the image and text properly
                }

                img {
                    max-width: 40%; // Adjust image size proportionally
                    height: auto;
                    border: 2px solid #e9ecef;
                    border-radius: 0.5rem;
                    transition: border-color 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        border-color: #033a71;
                    }
                }

                h5 {
                    margin-bottom: 0.5rem;
                    font-size: 1.25rem;
                    color: #343a40;
                }

                a {
                    text-decoration: none;
                    color: #033a71;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .carousel-indicators {
            bottom: -2rem;

            button {
                background-color: rgb(52, 58, 64);
                ;
            }

            li {
                background-color: #033a71;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 0 5px;

                &:hover {
                    background-color: #033a71;
                }
            }

            .active {
                background-color: #033a71;
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            width: 5%;
            color: #033a71;

            &:hover {
                color: #033a71;
            }

            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                background-color: #033a71;
                border-radius: 50%;
                padding: 0.5rem;
            }
        }
    }
}

.modal {
    .modal-body {
        .carousel {
            .carousel-inner {
                .carousel-item {
                    display: none; // Hide all slides initially
                    position: relative;
                    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;

                    &.active {
                        display: block; // Show only the active slide
                    }

                    img {
                        max-height: 70vh;
                        width: auto;
                        margin: 0 auto;
                        border-radius: 0.5rem;
                    }
                }
            }

            .carousel-indicators {
                position: absolute;
                bottom: 10px; // Position dots at the bottom of the modal
                display: flex;
                justify-content: center;

                button {
                    background-color: rgb(52, 58, 64);
                }

                li {
                    background-color: #033a71; // Dots color
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin: 0 5px;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: #033a71;
                    }
                }

                .active {
                    background-color: #033a71;
                }
            }

            .carousel-control-prev,
            .carousel-control-next {
                color: #033a71;

                z-index: 999; // Ensure controls are above other content

                &:hover {
                    color: #033a71;
                }

                .carousel-control-prev-icon,
                .carousel-control-next-icon {
                    background-color: #033a71;
                    border-radius: 50%;
                    padding: 0.5rem;
                }
            }
        }
    }
}

.contact-button {
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
}

.education-card {
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        cursor: pointer;
    }
}

.skills-section {
    background-color: #f9f9f9;
    padding: 60px 0;
}

.skill-card {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.skill-card h5 {
    font-weight: bold;
    color: #333333;
}

.progress-bar {
    background-color: #033a71 !important;
}

.badge {
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: capitalize;
}

.contact-section {
    background: #343a40;
    color: #ffffff;

    h2,
    p {
        color: #ffffff;
    }

    .btn-outline-primary {
        color: #007bff;
        border-color: #007bff;

        &:hover {
            background-color: #007bff;
            color: #ffffff;
        }
    }

    .btn-outline-secondary {
        color: #ffffff;
        border-color: #6c757d;

        &:hover {
            background-color: #6c757d;
            color: #ffffff;
        }
    }

    .social-button i {
        font-size: 1.5rem;
    }
}

.navbar {
    background-color: #343a40;

    .navbar-brand {
        font-size: 1.25rem;
        color: #ffffff;
        transition: color 0.3s ease;

        &:hover {
            color: #007bff;
        }
    }

    .nav-item {
        color: #ffffff;
        font-size: 1rem;
        margin-right: 1rem;
        transition: color 0.3s ease;

        &:hover {
            color: #007bff;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .navbar-toggler {
        border-color: rgba(255, 255, 255, 0.1);

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
}

.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 15px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-group {
        display: flex;
        gap: 10px;
    }

    .cookie-policy-link {
        color: #007bff;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: #ffffff;
        }
    }
}

.revoke-consent {
    position: fixed;
    bottom: 60px;
    right: 20px;
    background: rgba(52, 58, 64, 0.8);
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        bottom: 60px;
        background: rgba(52, 58, 64, 1);
        border-radius: 5px;
        padding: 10px 20px;

        .revoke-text {
            display: inline;
            margin-left: 8px;
        }
    }

    .revoke-text {
        display: none;
        font-size: 0.875rem;
    }
}